import Rebase from "re-base";
import firebase from "firebase";

// TEST
// const firebaseApp = firebase.initializeApp({
// 	apiKey: "AIzaSyCCr2yh56LWroza5NQvaPjp1Domjk71e3I",
// 	authDomain: "test-preflopmastery.firebaseapp.com",
// 	databaseURL: "https://test-preflopmastery-default-rtdb.firebaseio.com",
// 	projectId: "test-preflopmastery",
// 	storageBucket: "test-preflopmastery.appspot.com",
// 	messagingSenderId: "773681413812",
// 	appId: "1:773681413812:web:81896a2be548ae0e2321e1",
// });

// PROD
// const firebaseApp = firebase.initializeApp({
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: 'aadojo-8bbac.firebaseapp.com',
//   databaseURL: 'https://aadojo-8bbac-default-rtdb.firebaseio.com',
//   projectId: 'aadojo-8bbac',
//   storageBucket: 'aadojo-8bbac.appspot.com',
//   messagingSenderId: '924560636398',
//   appId: '1:924560636398:web:64b8fde538a8d94d6236ff',
// });

// developer-firebase account for development server
const firebaseConfig = {
  apiKey: "AIzaSyDpDS7AaQ8z5ndOXcP4HgWYLEeKDynqMAg",
  authDomain: "preflop--masterydev.firebaseapp.com",
  databaseURL:
    "https://preflop--masterydev-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "preflop--masterydev",
  storageBucket: "preflop--masterydev.appspot.com",
  messagingSenderId: "185004953556",
  appId: "1:185004953556:web:2704f26be3504ed5d2d041",
  measurementId: "G-VGHJ7EET2J",
};
const firebaseApp = firebase.initializeApp(firebaseConfig);
// https://firebase.google.com/docs/reference/js/v8/firebase.analytics.Analytics
firebase.analytics();
firebase.analytics().logEvent("login");

const base = Rebase.createClass(firebaseApp.database());

const storage = firebase.storage();
const firestoredb = firebase.firestore();

// firestoredb
// 	.collection("products")
// 	.get()
// 	.then((querySnapshot) => {
// 		querySnapshot.forEach((doc) => {
// 			console.log(doc.data());
// 		});
// 	});

export { storage, firestoredb };

export default base;
